import React, { useRef, useState } from 'react';
import '../Contact.css';
import Footer from '../components/Footer';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const formRef = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    debugger;
    e.preventDefault();

    emailjs.sendForm('service_imlr70q', 'template_19dpoun', formRef.current, {// service_imlr70q
        publicKey: 'AqkrptxKYN-eI15yw',
      })
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setShowSuccessMessage(true);
        },
        (error) => {
          console.error('Email sending failed:', error.text);
         
        }
      );
  };
  const handleClick = (e) => {
    e.preventDefault();
    sendEmail(e);
  };


  return (
    <>
      <div className='mianabout'>
        <img className='aboutfirstimage img-fluid' alt='network error' src='/images/groupaboutt.png' />
        <div className='about-overlay'>
          <h1 className='firstcont'>CONTACT US</h1>
          <p className='firstcont'>
            HOME<span className='landus'>/CONTACT US</span>
          </p>
        </div>
      </div>

      <div className='container-fluid mt-5 formcard'>
        <div className='row'>
          <div className='col-md-6'>
            <p className='statusabout'>
              <span className='lines'></span>GET IN TOUCH
            </p>
            <h2 className='mt-5 text-capitalize kmdtheme'>Write a Message To Our Experts</h2>
            <div className='d-flex mt-5 container'>
              <img className='callus img-fluid' src='/images/callus.png' />
              <div>
                <h4 className='nomber px-3'>+971 52 474 4983</h4>
                <h4 className='nomber '>+971 52 474 4983</h4>
                <p className='lead ourphone px-3'>Our Phone</p>
              </div>
              
            </div>

            <div className='d-flex mt-5 container'>
              <img className='callus' src='/images/mailus.png' />
              <div>
                <h4 className='nomber px-3'>info@kmd-me.com</h4>
                <p className='lead ourphone px-3'>Our Email</p>
              </div>
            </div>

            <div className='d-flex mt-5 container'>
              <img className='callus' src='/images/location.png' />
              <div>
                <h4 className='nomber px-3'>102 Shams Business Center, Sharjah Media City, UAE</h4>
                <p className='lead ourphone px-3'>Our Location</p>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <h2 className='kmdtheme'>Send Us a Message</h2>
            <form ref={formRef}>
              <input type='text' name='user_name' className='inputfields mt-5 py-3 lead' placeholder='Full Name' />
              <input name='user_email' type='text' className='inputfields mt-3 py-3 lead' placeholder='Email Address' />
              {/* <input type='message' name='message' className='inputfields mt-3 inputpadding lead' placeholder='Write Your Message' /> */}
              <textarea name='message' class='inputfields mt-3 inputpadding lead' placeholder='Write Your Message'></textarea>

              <button type='button' onClick={handleClick} className='quote px-4 py-2 mt-4'>
                Send Message
              </button>
            </form>
            {/* Success message */}
            {showSuccessMessage && (
              <div className='success-message'>
                <h3 className='mt-3'>Email sent successfully!</h3>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='container-fluid consultation mt-5 py-3'>
        <div className='row'>
          <div className='col-md-8'>
            <h1 className='ready'>Get Free Consultation Now! We Are Ready To Help Your Business</h1>
          </div>
          <div className='col-md-4 gone'>
            <button className='quotett px-4 py-2 mt-4 '>Get Free Consulting</button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
