import React from 'react'
import '../About.css'
import Footer from '../components/Footer'


const About = () => {

return(
    <>
    <div className='mianabout'>
    <img className='aboutfirstimage img-fluid' alt='network error' src='/images/groupaboutt.png'/>
    <div className='about-overlay'>
<h1 className='firstcont'>ABOUT US</h1>
<p className='firstcont'>HOME<span className='landus'>/ABOUT US</span></p>
    </div> 
    </div>


    <div className="container-fluid cardone">
            <div className="container-fluid mt-5">
            <div className="row">
             <div className="col-md-6 ">
                    <img className="img-fluid strategy" src="/images/visionag.png" alt="img"/>
                
                    
             </div>
            <div className="col-md-6 ">
            {/* <hr className='vertical-hr'></hr> */}
            <p className="statusabout"><span className="lines"></span>About KMD Middle East:</p>
            {/* <h2 className=" text-capitalize">Smart Solution For Your Digital Business</h2> */}
            <p className=" text-capitalize helocss rapidly">KMD Middle East is one of the UAE leading Professional Management and Consultancy Firm with specialties in Accounting & Bookkeeping, Audit & Assurance, Financial & Business Advisory, Taxation, ERP Implementation and Support, Company Incorporation, and other professional services in the field of Business and Finance.
We specialize in providing a comprehensive suite of financial solutions designed to unlock the full potential of businesses. With our diverse expertise and tailored strategies, we work closely with clients to address their specific needs and challenges, ensuring effective financial management and sustainable growth.
Whether it’s optimizing accounting processes, navigating complex tax regulations, or enhancing operational efficiency through ERP systems, our team is dedicated to delivering results that elevate
businesses to new heights.
By partnering with us, clients benefit from personalized guidance, proactive insights, and hands-on support that empowers them to make informed decisions and achieve their business objectives.
With KMD Middle East as your trusted financial partner, you can confidently navigate the complexities of the business landscape and drive success.
<h4 className='statusabout'>Our Vision:</h4>
Inspired by the founding Fathers of the UAE and His Highness Sheikh Mohammad Bin Rashid Al Maktoum, We strive for excellence in everything we undertake and the upliftment of our community.

<h1 className='statusabout'>Our Mission:</h1>
Our mission is to serve the clients to their utmost satisfaction that helps them to make the best business decisions which add value to their businesses.
.</p>
               {/* <ul>
                <li className="data">Strategy and Innovation</li>
                <li className="data">Data Analytics and Solutions</li>
                <li className="data">Sales and Market Research</li>
               </ul>
           <Link to="/team"> <button className="quote px-4 py-2 mt-4">Meet Our Team</button> </Link> */}
            </div>
            </div>


            </div>
           
            </div>




 {/* carseel */}
<div id="testimonialCarousel" className="carousel slide mt-5" data-ride="carousel">
 
 <ul class="carousel-indicators">
   <li data-target="#testimonialCarousel" data-slide-to="0" class="active"></li>
   <li data-target="#testimonialCarousel" data-slide-to="1"></li>
   <li data-target="#testimonialCarousel" data-slide-to="2"></li>
 </ul>
 
 
 <div className="carousel-inner">
   <div className="carousel-item active">
     <div className="carousel-caption">
       <h1>TESTIMONIALS</h1>
       <h3>What Our Clients Are Saying ?</h3>
       <p>"I can't thank the tax experts at KMD Middle East enough for their invaluable support and assistance..."</p>
     </div>
   </div>
   <div className="carousel-item ">
       <div className="carousel-caption">
         <h3>Celina Trading LLS, Dubai,UAE</h3>
         <p>Director</p>
         <p>"I can't thank the tax experts at KMD Middle East enough for their invaluable support and assistance..."</p>
       </div>
     </div>
     <div className="carousel-item ">
       <div className="carousel-caption">
         <h3>Celina Trading LLS, Abu Dhabi,UAE</h3>
         <p>Director</p>
         <p>"I can't thank the tax experts at KMD Middle East enough for their invaluable support and assistance..."</p>
       </div>
     </div>

   <div className="carousel-item">
   
   </div>
   <div className="carousel-item">
     
   </div>
   
 </div>
 

 <a className="carousel-control-prev" href="#testimonialCarousel" role="button" data-slide="prev">
   <span className="carousel-control-prev-icon" aria-hidden="true"></span>
   <span className="sr-only"></span>
 </a>
 <a className="carousel-control-next" href="#testimonialCarousel" role="button" data-slide="next">
   <span className="carousel-control-next-icon" aria-hidden="true"></span>
   <span className="sr-only"></span>
 </a>
</div>



<div className="container-fluid  cardonesecond cardsstatus">
  <div className="row">
    <div className="col-md-6 px-5 mt-5">
      <p className="statusaboutagain"><span className="lines responsive-heading"></span>W H Y <span className="skills">C H O O S E  </span><span className="skills"> U S  </span>  </p>
      <h2 className="mt-5 text-capitalize">We Provide The Best Services For Your Digital Business</h2>
      <p className='py-4'>Elevate your choice. We stand out with innovation, dedication, and a commitment to your success because exceptional service is our promise, and your journey deserves nothing less.</p>
    <div className='d-flex'>
        <img className='personpng' src='/images/person.png'/>
        <div className='px-3'>
        <h1>50+</h1>
        <p className='global'>Happy Global Customers Who Trusted Us</p>
        </div>
        
    </div>
    <hr></hr>
    <div className='d-flex mt-5'>
        <img className='personpng' src='/images/paperfold.png'/>
        <div className='px-3'>
        <h1>80+</h1>
        <p className='global'>Projects Completed In The Last 10 Years</p>
        </div>
   </div>
    </div>

    <div className="col-md-6 px-5 position-relative"> 
      <img className="img-fluid strategylike teamone" src="/images/afterteam.png" alt="First Image"/>
    
      <img className='teamtwo position-absolute' src='/images/afterteamtwo.png' />
    </div>
 </div>

 </div>
       
         <Footer/>   
    </>
)

}

export default About;