import React, { useState } from 'react';
import Footer from './Footer';
import '../Service.css'; // Assuming you have a CSS file for styling

const Service = () => {
  const services = {
    Bookkeeping: {
      title: 'Accounting & Bookkeeping',
      img: "/images/Accounting.jpg",
      head: "Accounting & Bookkeeping",
      description: (
        <>
        

        <p><strong>Financial Record Management:</strong> Our services involve the systematic recording, organizing, and tracking of financial transactions. This includes purchases, sales, receipts, and payments.</p>
          <p><strong>Financial Reporting:</strong> Our Professionals in this field generate financial reports like Income Statements, Balance Sheets, and Cash Flow Statements. These reports provide insights into the financial health and performance of an entity.</p>
          <p><strong>Budgeting and Forecasting:</strong> Our team assist you in creating budgets and financial forecasts based on historical data and future projections. This helps businesses plan and make informed financial decisions.</p>
          <p><strong>Tax Preparation and Compliance:</strong> Our services typically include preparing and filing tax returns for individuals, businesses, and organizations. Ensuring compliance with UAE Tax laws and regulations is a crucial aspect of this service.</p>
          <p><strong>Audit Support:</strong> We also provide support during audits by preparing necessary documentation, explaining financial processes, and ensuring compliance with auditing standards.</p>
          <p><strong>Financial Analysis:</strong> Beyond recording transactions, our team analyze financial data to identify trends, assess performance, and offer strategic recommendations for improving financial efficiency and profitability.</p>
          <p><strong>Payroll Processing:</strong> Our accounting services also offer payroll processing, managing employee compensation, tax withholdings, and payroll reporting.</p>
          <p>In summary, our accounting and bookkeeping services encompass a range of financial management activities aimed at maintaining accurate records, ensuring compliance, facilitating decision-making, and optimizing financial performance.</p>
    
        
             </>
      )  },
    Audit: {
      title: 'Audit and Assurance',
      img: "/images/romanfive.png",
      head: "Elevating Business Integrity with Expert Audit Services",
      description: (
        <>
          <p><strong>Independent Examination:</strong> Our Audit and Assurance involve an independent and objective examination of financial statements, transactions, system, and processes to ensure their accuracy, reliability and compliance with relevant standards and regulations.</p>
          <p><strong>Verification of Financial Information:</strong> We ensure the accuracy and completeness of financial information presented in an organization's financial statements. This includes confirming the existence, valuation, and ownership of assets, as well as the completeness and accuracy of liabilities.</p>
          <p><strong>Risk Assessment:</strong> We assess the risk factors affecting an organization's financial reporting process, including internal controls, potential fraud, and compliance risks. This helps stakeholders to understand the reliability of the financial information provided.</p>
          <p><strong>Compliance Monitoring:</strong> We evaluate whether financial statements comply with applicable accounting standards, regulations, and legal requirements. This ensures that the organization's financial reporting is transparent and follows best practices.</p>
          <p><strong>Internal Control Evaluation:</strong> Our services include assessing the effectiveness of internal control systems designed to safeguard assets, prevent fraud, and ensure the accuracy of financial reporting. Recommendations for improvement are provided where weaknesses are identified.</p>
          <p><strong>Assurance on Non-Financial Information:</strong> Beyond financial data, we may also provide assurance on non-financial information such as sustainability reports, corporate governance practices, and other key performance indicators.</p>
          <p><strong>Stakeholder Confidence:</strong> The primary objective of audit and assurance services is to enhance stakeholders' confidence in the reliability and integrity of financial information. This includes shareholders, investors, lenders, regulators, and other interested parties.</p>
          <p><strong>Reporting and Communication:</strong> Audit findings and conclusions are typically communicated through the auditor's report, which provides an opinion on the fairness of the financial statements and any relevant disclosures. This report is essential for stakeholders to make informed decisions.</p>
          <p><strong>Continuous Improvement:</strong> We also provide recommendations for improving financial reporting processes, internal controls, and risk management practices based on their findings. This helps organizations enhance efficiency, effectiveness, and transparency.</p>
          {/* <p>In summary, audit and assurance services serve to provide stakeholders with confidence in the accuracy, reliability, and compliance of financial information, ultimately contributing to sound decision-making and trust in the financial markets.</p> */}
        </>
      )
    },
    VatRegistration: {
      title: 'Value Added TAX (VAT)',
      img: "/images/woman.png",
      head: "Value Added TAX (VAT)",
      description:( <>
      <p>Since the implementation of Value Added Tax in UAE, our experienced team has endeavored to explain and simplify the VAT process for their clients while helping in simplified fashion to ensure their business functions are VAT compliant. This allows our clients to focus on their business and not get overwhelmed by the tax imposition. We offer the following VAT related services.</p>
      <p><strong>VAT Registration, De-registration, and Group Registration:</strong> Navigating the VAT registration process is crucial for businesses. We assist in the registration, de-registration, and group registration, ensuring that your business is compliant with VAT regulations.</p>
      <p><strong>VAT Assessment:</strong> In case of VAT assessment, we provide support to address any queries or concerns, ensuring that you are well-prepared to meet regulatory requirements.</p>
      <p><strong>VAT Health Check:</strong> Our VAT health check service reviews your VAT processes and documentation to identify potential issues and ensure compliance.</p>
      <p><strong>VAT Record Amendments:</strong> For any necessary amendments to your VAT records, we provide guidance and support to maintain accurate and up-to-date records.</p>
      <p><strong>VAT Training:</strong> Understanding VAT is key to compliance. We offer training to equip you and your team with the knowledge and skills required to manage VAT effectively.</p>
      <p><strong>VAT Return Filing:</strong> Efficient VAT return filing is essential for compliance. Our experts streamline the process, making sure your returns are accurate and submitted on time.</p>
      <p><strong>VAT Reconsideration:</strong> In case of disputes or reconsideration, we represent your interests and work towards a fair resolution.</p>
      <p><strong>Penalty Reconsideration:</strong> If you are facing VAT penalties, we assist in seeking reconsideration and minimizing potential financial impacts.</p>
      <p><strong>Tax Audit Representation:</strong> In case of tax audit, we provide expert representation and guidance throughout the audit process.</p>
      <p><strong>VAT Refund:</strong> We facilitate the VAT refund process for eligible businesses, ensuring a smooth and efficient refund experience for our clients.</p>
    </>)
    },
    VatReturnFiling: {
      title: 'Corporate Tax (CT)',
      img: "/images/aboutcompany.png",
      head: "Corporate Tax (CT)",
      description:  (
        <>
          <p>Our Corporate Tax Service assists you in successfully implementing Corporate Tax to your business in compliance with the tax regime, filing your tax returns, and providing complete tax support through the following steps.</p>
          <p><strong>Corporate Tax Registration:</strong> Our tax consultant will assist you in the registration of Corporate Tax (CT), ensuring that your business is compliant with CT regulations.</p>
          <p><strong>Assessment of Tax Obligations:</strong> Our tax consultant will review your business activities, financial records, and other relevant information to determine the taxes that your business is required to pay.</p>
          <p><strong>Tax Planning:</strong> Based on the assessment, the tax consultant will advise on tax-saving strategies and opportunities to minimize your tax liability.</p>
          <p><strong>Preparation and Filing of Tax Return:</strong> The tax consultant will prepare corporate tax reports and file Corporate tax returns on behalf of your business, ensuring accuracy and compliance with tax regulations.</p>
          <p><strong>Payment of Corporate Tax:</strong> The tax consultant will ensure that your business pays the tax on time and in the correct amount to avoid any penalties.</p>
          <p><strong>Representation in case of Tax Audit:</strong> In the event of a tax audit, the tax consultant will represent your business and provide support and guidance to ensure a smooth process.</p>
          <p><strong>Ongoing support and advice:</strong> The tax consultant will provide ongoing support and advice on tax-related matters to ensure that your business remains compliant with tax regulations.</p>
        </>
      )
    },
    VatHealthCheck: {
      title: 'Excise Tax',
      img: "/images/serviceconsult.png",
      head: "Excise Tax",
      description: (
        <>
          <p>Excise tax is an indirect tax generally levied on goods that are considered harmful to the health of the general public. The aim of the excise tax is therefore to discourage the consumption of those specific goods by the general public.</p>
          <p>It is payable by any person engaged in the activities listed below, specifically:</p>
          <p><strong>Importers of excise goods</strong></p>
          <p><strong>Producers of excise goods</strong></p>
          <p><strong>Persons releasing goods from an excise tax designated zone</strong></p>
          <p><strong>Warehouse keepers</strong></p>
          <p><strong>Stockpilers of excise goods, in certain cases</strong></p>
          <p>There may also be a limited number of cases where excise tax becomes the duty of another person involved in any of the above activities, where the person originally liable for the tax fails to pay. In all other cases, where a business or consumer purchases excise goods within the UAE, they can usually expect that excise tax has been accounted for on the goods within the UAE by the supplier (and will be included in the purchase price for the goods). In certain cases, excise goods will be physically labeled to indicate that excise tax has been paid in respect of those goods.</p>
          <p>We can assist you on excise tax issues and will be providing below services on your demand:</p>
          <p><strong>Excise Tax Registration</strong></p>
          <p><strong>Excise Tax Return Filing</strong></p>
          <p><strong>Excise Designated Zone Registration</strong></p>
          <p><strong>Excise Compliance Services</strong></p>
          <p><strong>Excise Product Registration</strong></p>
        </>
      )
    },
    CorporateTaxRegistration: {
      title: 'Economic Substance Regulation (ESR)',
      img: "/images/aboutcompany.png",
      head: "Economic Substance Regulation (ESR)",
      description: (
        <>
          <p>Economic Substance Regulation became effective in the UAE on January 01, 2019. This regulation requires companies to fill two broad forms:</p>
          <p><strong>Notification Form</strong></p>
          <p><strong>Annual Substance Return</strong></p>
          <p>The filing requirements depend upon the Regulatory Authority, which is relevant to the business, thus causing it to vary. It is essential for every business in the UAE to assess whether they fall under the UAE Economic Substance rules and what their obligations are. We provide assistance with the following services in order to avoid non-compliance penalties.</p>
          <p><strong>Business Activities evaluation eligible for Economic Substance Reporting</strong></p>
          <p><strong>Analysis and notification to the Relevant Authorities</strong></p>
          <p><strong>Support in filling Annual ESR Return</strong></p>
          <p><strong>ESR Compliance</strong></p>
        </>
      )
    },
    BusinessProcessAnalysis: {
      title: 'Management Consultancy',
      img: "/images/romanone.png",
      head: "Management Consultancy",
      description: (
        <>
          <p>The constant challenge that every company faces is to gain an advantage against its competitors while satisfying its stakeholders. Our specialist consultants will provide assistance throughout the journey, starting from the formation of the company. They will ensure the company is equipped with the needed expertise and knowledge to survive in the market by assisting decision making, implementing strategic policies, reducing costs, and much more. The following services are offered by our experienced consultants.</p>
          <ul>
            <li>Company Formation</li>
            <li>Change Management</li>
            <li>Corporate Governance</li>
            <li>Strategy Development</li>
            <li>Policies & Procedures</li>
            <li>Internal Controls</li>
            <li>Feasibility Studies</li>
            <li>Legal Services</li>
          </ul>
        </>
      )
    }, 
    ERP: {
      title: 'Odoo ERP',
      img: "/images/romantwo.avif",
      head: "Odoo ERP",
      description: (
        <>
          <p>Odoo ERP offers a comprehensive suite of business management applications designed to streamline operations and drive growth for organizations of all sizes. Here are a few benefits of using Odoo:</p>
          <ul>
            <li><strong>All-in-One Solution:</strong> Odoo integrates various business functions such as E-commerce, CRM, Sales, Purchase, Inventory, Accounting, HR, Social Media Marketing, and more into a single platform, eliminating the need for maintaining multiple ERPs.</li>
            <li><strong>Customization and Scalability:</strong> With its modular structure and open-source architecture, Odoo allows businesses to tailor the software to their specific needs and scale as their requirements evolve.</li>
            <li><strong>Cost-Effective:</strong> Odoo's open-source nature means lower initial costs compared to proprietary software. Additionally, its modular approach enables businesses to select only the applications they need, reducing unnecessary expenses.</li>
            <li><strong>User-Friendly Interface:</strong> Odoo boasts an intuitive and user-friendly interface, making it easy for employees to adopt and use the software with minimal training.</li>
            <li><strong>Streamlined Processes:</strong> By automating repetitive tasks and integrating business processes, Odoo helps organizations optimize efficiency, reduce errors, and improve productivity.</li>
            <li><strong>Real-Time Insights:</strong> Odoo provides real-time visibility into key business metrics and performance indicators through customizable dashboards and reports, enabling informed decision-making.</li>
            <li><strong>Mobile Accessibility:</strong> With mobile-responsive design and native mobile apps, Odoo allows users to access and manage their business operations from anywhere, anytime, enhancing flexibility and responsiveness.</li>
            <li><strong>Comprehensive Ecosystem:</strong> From modules and apps to integrations and third-party extensions, Odoo offers a rich ecosystem of solutions to address diverse business needs and industry-specific requirements.</li>
          </ul>
          <p>Our Odoo implementation methodology comprises several key components and considerations that are vital for successful implementation:</p>
          <ul>
            <li>Needs Assessment</li>
            <li>Project Planning</li>
            <li>Configuration and Customization</li>
            <li>Data Migration</li>
            <li>Integration</li>
            <li>Training and Change Management</li>
            {/* <li>Testing and Quality Assurance</li> */}
            {/* <li>Go-Live Support</li> */}
            {/* <li>Post-Implementation Support</li> */}
            {/* <li>Documentation and Knowledge Transfer</li> */}
          </ul>
          {/* <p>By including these components in the Odoo implementation services, you can ensure a successful and seamless deployment of Odoo that delivers tangible benefits and value to the client's business.</p> */}
        </>
      )
    },
    ErpAuditServices: {
      title: 'Hyperion Financial Management (HFM):',
      img: "/images/romanthree.png",
      head: "Hyperion Financial Management (HFM):",
      description: (
        <>
          <p>Our Hyperion Financial Management (HFM) audit service offers comprehensive analysis and evaluation of your HFM system to ensure its effectiveness, accuracy, and compliance with financial standards and regulations. Here are the key aspects of our HFM audit service:</p>
          <ul>
            <li><strong>Financial Consolidation Review:</strong> We conduct a thorough review of your HFM system's financial consolidation process, including data aggregation, currency translation, intercompany eliminations, and consolidation adjustments, to ensure accuracy and completeness.</li>
            <li><strong>Data Integration and Validation:</strong> Our audit includes an assessment of data integration processes and data quality controls within HFM. We validate data sources, mappings, and transformations to ensure the integrity and reliability of financial data.</li>
            <li><strong>Calculation and Reporting Analysis:</strong> We review complex financial calculations, business rules, and reporting functionalities within HFM to identify potential errors, inconsistencies, or inaccuracies. Our goal is to ensure that financial reports generated by HFM adhere to GAAP and IFRS standards.</li>
            <li><strong>Security and Access Controls:</strong> Our audit evaluates the security framework of your HFM system, including user roles, permissions, and data access controls. We identify any security vulnerabilities and recommend enhancements to ensure data confidentiality and integrity.</li>
            <li><strong>Workflow and Approval Processes:</strong> We assess workflow management features and approval processes within HFM to streamline financial consolidation tasks and improve collaboration among users. Our audit ensures that consolidation tasks are completed efficiently and accurately.</li>
            <li><strong>Performance Optimization:</strong> Our audit includes performance analysis and optimization recommendations to enhance the scalability and processing efficiency of your HFM system. We identify bottlenecks and suggest improvements to optimize system performance.</li>
            <li><strong>Compliance Assurance:</strong> We verify that your HFM system complies with regulatory requirements and industry standards for financial reporting. Our audit helps mitigate compliance risks and ensures that your organization maintains regulatory compliance.</li>
          </ul>
          <p>By conducting an HFM audit, you can identify areas for improvement, mitigate risks, and optimize the performance and compliance of your financial consolidation and reporting processes. Our experienced auditors provide actionable recommendations to enhance the effectiveness and reliability of your HFM system.</p>
        </>
      )
      
    },
    BusinessValuation: {
      title: 'Oracle Application:',
      img: "/images/romanfour.png",
      head: "Oracle Application:",
      description: (
        <>
          <p>Our Oracle Applications evaluation service offers a comprehensive analysis of your organization's business processes and requirements to determine the suitability and potential benefits of Oracle Applications implementation. Here are the key aspects of our business evaluation service:</p>
          <ul>
            <li><strong>Needs Assessment:</strong> We conduct a thorough assessment of your organization's business processes, functional requirements, and strategic objectives to identify areas for improvement and opportunities for automation.</li>
            <li><strong>Gap Analysis:</strong> Our evaluation includes a gap analysis to compare your existing business processes and IT infrastructure with the capabilities and features of Oracle Applications. We identify gaps, inefficiencies, and areas where Oracle Applications can add value.</li>
            <li><strong>Fitment Analysis:</strong> We evaluate the fitment of Oracle Applications modules and solutions with your organization's business requirements and industry-specific needs. Our goal is to determine the suitability of Oracle Applications for addressing your unique business challenges.</li>
            <li><strong>ROI Assessment:</strong> We assess the potential return on investment (ROI) of Oracle Applications implementation by analyzing cost savings, productivity gains, revenue growth opportunities, and other tangible and intangible benefits. Our evaluation helps you make informed decisions about the financial viability of adopting Oracle Applications.</li>
            <li><strong>Risk Analysis:</strong> We conduct a risk analysis to identify potential challenges, risks, and obstacles associated with Oracle Applications implementation, including technical complexity, organizational change management, data migration issues, and compliance concerns. Our goal is to develop risk mitigation strategies to ensure a successful implementation.</li>
            <li><strong>Recommendations:</strong> Based on our evaluation findings, we provide actionable recommendations and insights to guide your decision-making process. We offer customized solutions and implementation strategies tailored to your organization's goals, priorities, and constraints.</li>
          </ul>
          <p>By leveraging our Oracle Applications evaluation service, you can gain valuable insights into the potential benefits, challenges, and risks of adopting Oracle Applications. Our experienced consultants provide objective analysis and strategic guidance to help you make informed decisions and maximize the value of your investment in Oracle Applications.</p>
        </>
      )
      
    },
    
  };

  const [selectedService, setSelectedService] = useState(Object.keys(services)[0]); // Select the first service by default

  return (
    <>
      <div className='mianabout'>
        {/* Add any main about content here */}
      </div>

      <div className='container-fluid mt-5'>
        <div className='row'>
          <div className='col-md-5'>
            {Object.keys(services).map((key) => (
              <button
                key={key}
                onClick={() => setSelectedService(key)}
                className={`py-2 bookkeeping mb-2 ${selectedService === key ? 'selected' : ''}`}
              >
                {services[key].title}
              </button>
            ))}
          </div>
          <div className='col-md-7'>
            {selectedService && (
              <>
                <h4 className='taxi'>{services[selectedService].title}</h4>
                <img
                  src={services[selectedService].img}
                  alt={services[selectedService].title}
                  className='service-image'
                />
                <div className='discription_div firstcss'>

                <h4 className='mt-3 '>{services[selectedService].head}</h4>
                <p className='text-capitalize'>{services[selectedService].description}</p>
                </div>
             
              </>
            )}
          </div>
        </div>
      </div>

      {/* <div className='container-fluid mt-5'>
    <div className='row'>
      <div className='col-md-5'>


      </div>

      <div className='col-md-7'>
      
        <div className='mt-5'>
        <div>
            <h3 className='solutionkmd'>Solution We Give</h3>
        </div>
        <div className='d-flex mt-4'>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Tailored Financial Solutions</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Strategic Financial Planning</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Efficient Bookkeeping and Accounting</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Cash Flow Optimization</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Inventory Control and Valuation</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Payroll Precision</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Global Accounting Standards Adherence</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Software Proficiency</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Informed Decision Support</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Expert Guidance</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Commitment to Growth</h4>
        </div>
        
        </div>

      </div>
    </div>
</div>
      



   */}




<div className='container-fluid consultation mt-5 py-3'>
    <div className='row'>
        <div className='col-md-8'>
            <h1 className='ready'>Get Free Consultation Now! We Are Ready To Help Your Business</h1>

        </div>
        <div className='col-md-4 gone'> <button className="quotett px-4 py-2 mt-4 ">Get Free Consulting</button></div>
    </div>
</div>

      <Footer />
    </>
  );
};

export default Service;
